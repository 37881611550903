import { Component, OnDestroy, OnInit } from "@angular/core";
import { Block } from "./block";
import { Row } from "./row";
import { Column } from "./column";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Route } from "@angular/compiler/src/core";
import { ParamMap, ActivatedRoute } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { ReportService } from "src/app/services/report.service";
import { ToastrService } from "ngx-toastr";
import { JsonGenerator } from "src/app/util/jsonGenerator";
import { ProcessDataSource } from "src/app/services/process.datasource";
import { ProcessService } from "src/app/services/process.service";
import { saveAs } from "file-saver";
import { SharedService } from "src/app/services/sharedService";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
@Component({
  selector: "app-form-creator",
  templateUrl: "./form-creator.component.html",
  styleUrls: ["./form-creator.component.css"],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class FormCreatorComponent implements OnInit, OnDestroy {
  subscriptionAddRow: any;
  subscriptionUpdateRow: any;
  subscriptionMoveRow: any;
  subscriptionDeleteRow: any;
  subscriptionDeleteBlock: any;
  subscriptionRenameBlock: any;
  subscriptionColumnsInRows: any;

  subscriptionUpdateColumnType: any;
  subscriptionAddColumn: any;
  subscriptionDeleteColumn: any;
  subscriptionUpdateColumn: any;
  subscriptionFusionTable: any;

  formCreation = true;
  output: string;
  key = "page-1-key";
  name = "New Form";
  type = "page";

  blockKey: string;
  blockName: string;
  excelMapping: string;
  currentBlockIndex: number;
  blocks: Block[];
  blockStates = [];

  rowKey: string;
  rowName: string;
  rowType: string;
  rowMapping: string;
  rowStyle: string;
  rowFormula: string;
  rowDefault = true;
  rowHasAttachment = false;
  rowFusionTransfer = false;
  rowRequired = false;
  rowGlobal = false;
  rowBold = false;
  currentRowIndex: number;
  rows: Row[];
  rowStates = [];
  hasAttachment = false;
  fusionTransfer = false;
  columns: Column[];
  columnKey: string;
  columnType = "string";
  columnName: string;
  currentColumnIndex: number;
  columnStates = [];
  columnBold = false;
  staticTexts = {};
  bolds = {};
  formulas = {};
  checkFormulas = {};
  checkRequired = {};
  boldStaticText = {};
  styles = ["input", "group", "text"];
  rowTypes = ["string", "number", "date", "checkbox"];
  columnTypes = [
    "string",
    "number",
    "date",
    "static text",
    "checkbox",
    "option",
  ];
  blockPageTypes = ["page"];

  columnsInRows: boolean[];
  showFormulaSection = false;

  existingColunmKeyError = false;
  existingRowKeyError = false;
  missingRowKeyError = false;

  lastColumn = "";
  jsonObj: any;
  jsonObjAPI: any;

  fromProcess = false;

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
  reportId: any;
  stageId: any;
  onlyView: any;
  year: any;
  jsonGenerator: JsonGenerator;
  repdbId: any;
  constructor(
    private sharedService: SharedService,
    private http: HttpClient,
    private processService: ProcessService,
    private _location: Location,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.onlyView = this.route.snapshot.queryParamMap.get("onlyView");
    if (this.onlyView) {
      this.formCreation = false;
    }
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.reportId = params.get("reportId");
          this.stageId = params.get("stageId");
          this.year = params.get("year");
          this.route.queryParams.subscribe((paramsq) => {
            if (paramsq) {
              this.repdbId = paramsq["id"];
            }
          });
          return this.reportService.getReportPage(this.reportId, this.stageId);
        })
      )
      .subscribe((stagePage) => {
        if (!this.isEmpty(stagePage)) {
          this.fromProcess = true;
          this.jsonObj = JSON.parse(JSON.stringify(stagePage));
          this.key = this.jsonObj.key;
          this.excelMapping = this.jsonObj.excelMapping;
          this.name = this.jsonObj.name;
          this.type = this.jsonObj.type;

          this.blocks = this.jsonObj.blocks;
          this.jsonObjAPI = this.jsonGenerator.generateAPI(this.jsonObj);
          this.printOutput();
          if (this.blocks.length>0)
          this.selectBlock(0, false)
        }
      });
    this.jsonGenerator = new JsonGenerator();
  }

  selectionChange(event) {
    // if (event.value === 'formula') {
    //   this.showFormulaSection = true
    // }
    // else { this.showFormulaSection = false }
  }

  ngOnInit() {
    this.blocks = [];
    this.rows = [];
    this.columns = [];
    this.blockStates = [];
    this.columnsInRows = [];

    this.subscriptionUpdateColumnType = this.sharedService.updateColTypeEmitter.subscribe(
      (val) => {
        this.updateColumnType(val);
      }
    );
    this.subscriptionFusionTable = this.sharedService.updateFusionTableEmitter.subscribe(
      (val) => {
        this.updateFusionTable(val);
      }
    );

    this.subscriptionDeleteBlock = this.sharedService.deleteBlock.subscribe(
      (val) => {
        this.deleteBlock2(val);
      }
    );
    this.subscriptionRenameBlock = this.sharedService.renameBlock.subscribe(
      (val) => {
        this.renameBlock2(val.name, val.ind);
      }
    );

    this.subscriptionAddRow = this.sharedService.addRow.subscribe((val) => {
      this.addRow2(val.blockIndex, val.row, val.staticTexts);
    });

    this.subscriptionUpdateRow = this.sharedService.updateRow.subscribe(
      (val) => {
        this.updateRow2(val.blockIndex, val.row, val.rowIndex);
      }
    );

    this.subscriptionMoveRow = this.sharedService.moveRow.subscribe((val) => {
      this.moveRow(val.blockIndex, val.row, val.direction);
    });

    this.subscriptionDeleteRow = this.sharedService.deleteRowEmitter.subscribe(
      (val) => {
        this.deleteRow2(val.blockIndex, val.row);
      }
    );

    this.subscriptionAddColumn = this.sharedService.addColumn.subscribe(
      (val) => {
        this.addColumn2(val.blockIndex, val.column);
      }
    );

    this.subscriptionDeleteColumn =
      this.sharedService.deleteColumnEmitter.subscribe((val) => {
        this.deleteColumn2(val.blockIndex, val.column);
      });

    this.subscriptionUpdateColumn =
      this.sharedService.updateColumnEmmitter.subscribe((val) => {
        this.updateColumn2(val.blockIndex, val.column, val.colIndex);
      });

    this.subscriptionColumnsInRows =
      this.sharedService.columnsInRowsEmitter.subscribe((val) => {
        this.columnsInRows = val;
      });
     
      
  }

  ngOnDestroy(): void {
    if (this.subscriptionDeleteBlock) {
      this.subscriptionDeleteBlock.unsubscribe();
    }
    if (this.subscriptionRenameBlock) {
      this.subscriptionRenameBlock.unsubscribe();
    }
    if (this.subscriptionAddRow) {
      this.subscriptionAddRow.unsubscribe();
    }
    if (this.subscriptionUpdateRow) {
      this.subscriptionUpdateRow.unsubscribe();
    }
    if (this.subscriptionMoveRow) {
      this.subscriptionMoveRow.unsubscribe();
    }
    if (this.subscriptionDeleteRow) {
      this.subscriptionDeleteRow.unsubscribe();
    }

    if (this.subscriptionAddColumn) {
      this.subscriptionAddColumn.unsubscribe();
    }

    if (this.subscriptionDeleteColumn) {
      this.subscriptionDeleteColumn.unsubscribe();
    }

    if (this.subscriptionUpdateColumn) {
      this.subscriptionUpdateColumn.unsubscribe();
    }
  }

  selectBlock(index, skipRows) {
    this.currentBlockIndex = index;
    for (var i = 0; i < this.blockStates.length; i++) {
      this.blockStates[i] = false;
    }
    this.blockStates[index] = true;
    this.blockKey = this.blocks[index].key;
    this.blockName = this.blocks[index].name;
    this.rows = this.blocks[index].rows;
    this.columns = this.blocks[index]["columns"]
      ? this.blocks[index]["columns"]
      : [];
    if (!skipRows) {
      this.columnsInRows = [];
      for (var i = 0; i < this.columns.length; i++) {
        this.columnsInRows[i] = true;
      }
    }
  }

  generateBlockName() {
    var x = this.blocks.length + 1;
    while (
      this.blocks.find((elem) => elem.name === "Block " + x) !== undefined
    ) {
      x++;
    }
    return "Block " + x;
  }

  generateBlockKey() {
    var x = this.blocks.length + 1;
    while (this.blocks.find((elem) => elem.key === x + "") !== undefined) {
      x++;
    }
    return x + "";
  }

  addBlock() {
    var b = new Block();
    b.key = this.generateBlockKey();
    b.name =
      this.blockName === undefined || this.blockName === ""
        ? this.generateBlockName()
        : this.blockName;
    this.blocks.push(b);
    for (var i = 0; i < this.blockStates.length; i++) {
      this.blockStates[i] = false;
    }
    this.blockStates.push(true);
    this.currentBlockIndex = this.blocks.length - 1;
    this.selectBlock(this.currentBlockIndex, false);
    this.rows = [];
    this.columnsInRows = [];
    this.staticTexts = {};
    this.formulas = {};
    this.resetRowValues();
    setTimeout(() => {
      this.blockName = undefined;
    }, 100);
    this.printOutput();
  }

  renameBlock() {
    if (this.blockName !== undefined && this.blockName !== "")
      this.blocks[this.currentBlockIndex].name = this.blockName;
    this.printOutput();
  }
  renameBlock2(val, ind) {
    this.blocks[ind].name = val;
    this.printOutput();
  }

  deleteBlock(ind) {
    this.blocks.splice(this.currentBlockIndex, 1);
    if (this.currentBlockIndex === this.blocks.length) {
      this.currentBlockIndex -= 1;
    }
    if (this.currentBlockIndex > -1)
      this.selectBlock(this.currentBlockIndex, false);
    this.blockName = undefined;
    this.printOutput();
  }

  deleteBlock2(ind) {
    this.blocks.splice(ind, 1);
    if (ind === this.blocks.length) {
      ind -= 1;
    }
    if (ind > -1) this.selectBlock(ind, false);
    this.blockName = undefined;
    this.printOutput();
  }

  updateColumnType(val){
    this.blocks[val.blockIndex]['colType'] = val.columnType;
    this.printOutput()
  }

  updateFusionTable(val){
    this.blocks[val.blockIndex]['fusionTable'] = val.fusionTable;
  }
  

  resetBlockValues() {
    this.blockKey = null;
    this.blockName = null;
  }

  selectColumn(index) {
    this.currentColumnIndex = index;
    for (var i = 0; i < this.columnStates.length; i++) {
      this.columnStates[i] = false;
    }
    this.columnStates[index] = true;
    this.columnKey = this.columns[index].key;
    this.columnName = this.columns[index].name;
    this.columnType =
      this.columns[index].type === "static_text"
        ? "static text"
        : this.columns[index].type;
    this.columnBold = this.columns[index].bold;
  }

  generateColumnName() {
    var x = this.columns.length + 1;
    while (
      this.columns.find((elem) => elem.name === "Column " + x) !== undefined
    ) {
      x++;
    }
    return "Column " + x;
  }

  generateColumnKey() {
    var x = this.columns.length + 1;
    while (this.columns.find((elem) => elem.key === x + "") !== undefined) {
      x++;
    }
    return x + "";
  }

  addColumn() {
    if (
      this.columns.find((elem) => elem.key === this.columnKey) === undefined
    ) {
      var c = new Column();
      c.key =
        this.columnKey === undefined || this.columnKey === ""
          ? this.generateColumnKey()
          : this.columnKey;
      c.name =
        this.columnName === undefined || this.columnName === ""
          ? " "
          : this.columnName;
      c.type =
        this.columnType === "static text" ? "static_text" : this.columnType;
      c.bold = this.columnBold;
      this.columns.push(c);
      this.blocks[this.currentBlockIndex]["columns"] = this.columns;
      for (var i = 0; i < this.columnStates.length; i++) {
        this.columnStates[i] = false;
      }
      this.columnStates.push(true);
      this.columnsInRows.push(true);
      this.currentColumnIndex = this.columns.length - 1;
      this.selectColumn(this.currentColumnIndex);
      this.resetColumnValues();
      this.resetRowValues();
      this.printOutput();
      this.existingColunmKeyError = false;
    } else {
      this.existingColunmKeyError = true;
    }
  }

  updateColumn() {
    if (this.columnKey !== undefined && this.columnKey !== "") {
      if (
        this.columns.find((elem) => elem.key === this.columnKey) ===
          undefined ||
        this.columns.findIndex((elem) => elem.key === this.columnKey) ===
          this.currentColumnIndex
      ) {
        var x = this.columns[this.currentColumnIndex].key;
        this.columns[this.currentColumnIndex].name = this.columnName;
        this.columns[this.currentColumnIndex].key = this.columnKey;
        this.columns[this.currentColumnIndex].type =
          this.columnType === "static text" ? "static_text" : this.columnType;
        this.columns[this.currentColumnIndex].bold = this.columnBold;
        for (var i = 0; i < this.rows.length; i++) {
          this.rows[i]["values"].find((elem) => elem.column === x).column =
            this.columnKey;
        }
        this.printOutput();
        this.existingColunmKeyError = false;
      } else {
        this.existingColunmKeyError = true;
      }
    } else {
      console.log("something wicked");
    }
  }
  deleteColumn2(blockIndex, columnKey) {
    this.columns = this.blocks[blockIndex].columns;
    this.currentColumnIndex = this.columns.findIndex(
      (elem) => elem.key === columnKey
    );
    this.columns.splice(this.currentColumnIndex, 1);
    if (this.currentColumnIndex === this.columns.length) {
      this.currentColumnIndex -= 1;
    }
    this.rows.forEach((elem) => {
      for (var i = 0; i < elem["values"].length; i++) {
        if (elem["values"][i].column === columnKey) {
          elem["values"].splice(i, 1);
          break;
        }
      }
    });
    if (this.currentColumnIndex > -1)
      this.selectColumn(this.currentColumnIndex);
    this.resetColumnValues();
    this.printOutput();
  }
  deleteColumn() {
    var colKey = this.columns[this.currentColumnIndex].key;
    this.columns.splice(this.currentColumnIndex, 1);
    if (this.currentColumnIndex === this.columns.length) {
      this.currentColumnIndex -= 1;
    }
    this.rows.forEach((elem) => {
      for (var i = 0; i < elem["values"].length; i++) {
        if (elem["values"][i].column === colKey) {
          elem["values"].splice(i, 1);
          break;
        }
      }
    });
    if (this.currentColumnIndex > -1)
      this.selectColumn(this.currentColumnIndex);
    this.resetColumnValues();
    this.printOutput();
  }

  resetColumnValues() {
    this.columnKey = undefined;
    this.columnName = undefined;
    this.columnBold = false;
  }

  selectRow(index) {
    this.currentRowIndex = index;
    for (var i = 0; i < this.rowStates.length; i++) {
      this.rowStates[i] = false;
    }
    this.rowStates[index] = true;
    this.rowKey = this.rows[index].key;
    this.rowName = this.rows[index].name;
    this.rowType = this.rows[index].type;
    this.rowStyle = this.rows[index].style;
    this.rowDefault = this.rows[index].default;
    this.hasAttachment = this.rows[index].hasAttachment;
    this.fusionTransfer = this.rows[index].fusionTransfer;
    this.rowRequired = this.rows[index].required;
    this.rowGlobal = this.rows[index].global;
    this.rowBold = this.rows[index].bold;
    if (this.rows[index]["values"] !== undefined) {
      this.staticTexts = {};
      for (var i = 0; i < this.rows[index]["values"].length; i++) {
        if (
          this.rows[index]["values"][i].type !== undefined &&
          this.rows[index]["values"][i].type === "static_text"
        ) {
          this.staticTexts[this.rows[index]["values"][i].column] =
            this.rows[index]["values"][i].value;
        }
      }
      this.formulas = {};
      for (var i = 0; i < this.rows[index]["values"].length; i++) {
        this.showFormulaSection = true;
        if (this.rows[index]["values"][i].formula !== undefined) {
          this.formulas[this.rows[index]["values"][i].column] =
            this.rows[index]["values"][i].formula;
        }
        if (this.rows[index]["values"][i].required !== undefined) {
          this.checkRequired[this.rows[index]["values"][i].column] =
            this.rows[index]["values"][i].required;
        }
        if (this.rows[index]["values"][i].bold !== undefined) {
          this.boldStaticText[this.rows[index]["values"][i].column] =
            this.rows[index]["values"][i].bold;
        }
      }
    }
  }

  generateRowName() {
    return "Row " + (this.rows.length + 1);
  }
  generateRowKey() {
    var x = this.rows.length + 1;
    while (this.rows.find((elem) => elem.key === x + "") !== undefined) {
      x++;
    }
    return x + "";
  }

  deleteRow() {
    this.rows.splice(this.currentRowIndex, 1);
    if (this.currentRowIndex === this.rows.length) {
      this.currentRowIndex -= 1;
    }
    if (this.currentRowIndex > -1) this.selectRow(this.currentRowIndex);
    this.printOutput();
  }

  resetRowValues() {
    this.rowKey = undefined;
    this.rowName = undefined;
    this.rowType = undefined;
    this.rowStyle = undefined;
    this.rowDefault = true;
    this.hasAttachment = false;
    this.fusionTransfer = false;
    this.rowRequired = false;
    this.rowGlobal = false;
    this.staticTexts = {};
    this.formulas = {};
    this.checkFormulas = {};
    this.checkRequired = {};
    this.boldStaticText = {};
    this.bolds = {};
    this.lastColumn = "";
  }

  selectActiveColumn(index) {
    this.columnsInRows[index] = !this.columnsInRows[index];
  }

  printOutput() {
    this.jsonObj = null;
    setTimeout(() => {
      this.output =
        '{ "key":"' +
        this.key +
        '","name":"' +
        this.name +
        '","type":"' +
        this.type +
        this.type +
        '", "excelMapping":"' +
        this.excelMapping +
        '", "blocks":' +
        JSON.stringify(this.blocks) +
        " }";
      this.jsonObj = JSON.parse(this.output);
      this.jsonObjAPI = this.jsonGenerator.generateAPI(this.jsonObj);
    }, 0);
  }

  calculateBlockClasses(index) {
    return {
      btn: true,
      "selected-component": this.blockStates[index],
    };
  }

  calculateRowClasses(index) {
    return {
      btn: true,
      "selected-component": this.rowStates[index],
    };
  }

  calculateColumnClasses(index) {
    return {
      btn: true,
      "selected-component": this.columnStates[index],
    };
  }

  calculateColumnInRowsClasses(index) {
    return {
      btn: true,
      "selected-component": this.columnsInRows[index],
    };
  }

  addKeyToFormula(event) {
    this.formulas[this.lastColumn] =
      this.formulas[this.lastColumn] === undefined
        ? event
        : this.formulas[this.lastColumn] + " " + event;
    this.rowFormula =
      this.rowFormula === undefined ? event : this.rowFormula + " " + event;
  }

  selectLastFormula(col) {
    this.lastColumn = col.key;
  }

  formData() {
    return of(this.jsonObj);
  }
  apiData() {
    return of(this.jsonObjAPI);
  }

  downloadForm() {
    this.formData().subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: this.key + ".json",
        text: JSON.stringify(res, null, "\t"),
      });
    });
  }
  downloadAPI() {
    this.apiData().subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: this.name + ".json",
        text: JSON.stringify(res, null, "\t"),
      });
    });
  }

  downloadPDF() {
    var tempStage = {
      version: 1,
      key: "test",
      name: "test",
      active: true,
      stages: [this.jsonObj],
    };

    this.processService.downloadEditingPDF(tempStage).subscribe((data) => {
      const blob = data;
      const file = new Blob([blob], {});
      const filename = "document-" + Date.now() + ".pdf";
      saveAs(file, filename);
    });
  }

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement,
    },
  };

  private dyanmicDownloadByHtmlTag(arg: { fileName: string; text: string }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement("a");
    }
    const element = this.setting.element.dynamicDownload;
    const fileType =
      arg.fileName.indexOf(".json") > -1 ? "text/json" : "text/plain";
    element.setAttribute(
      "href",
      `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`
    );
    element.setAttribute("download", arg.fileName);
    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.fromProcess = false;
        var x = fileReader.result.toString();
        this.jsonObj = JSON.parse(x);
        this.key = this.jsonObj.key;
        this.excelMapping = this.jsonObj.excelMapping;
        this.name = this.jsonObj.name;
        this.type = this.jsonObj.type;
        this.blocks = this.jsonObj.blocks;
        this.jsonObjAPI = this.jsonGenerator.generateAPI(this.jsonObj);
        this.printOutput();
      };
      fileReader.readAsText(file);
    }
  }

  checkOtherBoxes(event) {
    var currentBlock = this.blocks[this.currentBlockIndex];
    if (currentBlock["columns"] !== undefined) {
      for (var c = 0; c < currentBlock["columns"].length; c++) {
        var col = currentBlock["columns"][c];
        if (event.checked) {
          this.checkRequired[col["key"]] = true;
        } else {
          this.checkRequired[col["key"]] = false;
        }
      }
    }
  }

  saveInProcess() {
    this.jsonObj = null;
    setTimeout(() => {
      this.output =
        '{ "key":"' +
        this.key +
        '","name":"' +
        this.name +
        '","type":"' +
        this.type +
        '", "excelMapping":"' +
        this.excelMapping +
        '", "blocks":' +
        JSON.stringify(this.blocks) +
        " }";
      this.jsonObj = JSON.parse(this.output);
      this.jsonObjAPI = this.jsonGenerator.generateAPI(this.jsonObj);
      var page = {
        reportId: this.reportId,
        stageId: this.stageId,
        stage: this.jsonObj,
      };

      //if (this.fromProcess){
      this.reportService.updateReportPage(page).subscribe((data) => {
        this.reportService
          .generateExcelMapping(this.repdbId)
          .subscribe((val) => {
            this.toastr.success(
              "Success!",
              `Stage ${this.stageId} successfully updated`,
              {
                toastClass: "ngx-toastr border-red",
                timeOut: 3000,
              }
            );
          });
      });
      // }
      // else{
      //   this.reportService.addReportPage(page).subscribe(data => {
      //     this.reportService.generateExcelMapping(this.repdbId).subscribe(val=>{
      //     this.toastr.success('Success!', `Stage ${this.stageId} successfully added`, {
      //       toastClass: "ngx-toastr border-red",
      //       timeOut: 3000
      //     });
      //   }) });
      // }
    }, 0);
  }

  addRow2(blockIndex, row, staticTexts) {
    if (this.blocks.length > 0) {
      this.selectBlock(blockIndex, true);
      this.rowMapping = row.mapping;
      this.rowKey = row.key;
      this.rowName = row.name;
      this.rowType = row.type;
      this.rowStyle = row.style;
      this.rowHasAttachment = row.hasAttachment;
      this.rowFusionTransfer = row.fusionTransfer;
      this.rowDefault = row.default;
      this.rowRequired = row.required;
      this.rowGlobal = row.global;
      this.rowBold = row.bold;
      this.rows = this.blocks[blockIndex].rows;
      if (this.rows.find((elem) => elem.key === this.rowKey) === undefined) {
        var r = new Row();
        r.key =
          this.rowKey === undefined || this.rowKey === ""
            ? this.generateRowKey()
            : row.key;
        r.name = this.rowName;
        r.style = this.rowStyle;
        r.mapping = this.rowMapping;
        r.default = this.rowDefault;
        r.hasAttachment = this.rowHasAttachment;
        r.fusionTransfer = this.fusionTransfer;
        r.required = this.rowRequired;
        r.global = this.rowGlobal;
        r.bold = this.rowBold;
        if (this.columns.length > 0) {
          r["values"] = [];
          for (var i = 0; i < this.columns.length; i++) {
            var value = { column: this.columns[i].key };
            if (
              this.columnsInRows[i] === true &&
              !["group", "text"].includes(r.style)
            ) {
              if (
                this.formulas[this.columns[i].key] === undefined ||
                this.formulas[this.columns[i].key] === ""
              ) {
            
                if (
                  this.rowType !== undefined &&
                  this.rowType !== "formula" &&
                  this.rowType !== ""
                )
                  value["type"] = this.rowType;
                else
                  value["type"] =
                    this.columns[i].type === "static text"
                      ? "static_text"
                      : this.columns[i].type;
              } else {
                if (value["type"] !== undefined) delete value["type"];
                value["formula"] = this.formulas[this.columns[i].key];
              }
              if (this.checkRequired[this.columns[i].key] !== undefined) {
                value["required"] = this.checkRequired[this.columns[i].key];
              }
              if (this.boldStaticText[this.columns[i].key] !== undefined) {
                value["bold"] = this.boldStaticText[this.columns[i].key];
              }
            }
            if (staticTexts[this.columns[i].key] !== undefined) {
              value["value"] = staticTexts[this.columns[i].key];
            }
            r["values"].push(value);
          }
        } else {
          if (this.rowType === "") {
            r.type = "string";
          } else {
            r.type = this.rowType;
          }
          if (this.rowStyle === "") {
            r.style = "input";
          } else {
            r.style = this.rowStyle;
          }
        }
        this.rows.push(r);
        this.blocks[blockIndex].rows = this.rows;
        for (var i = 0; i < this.rowStates.length; i++) {
          this.rowStates[i] = false;
        }
        this.rowStates.push(true);
        this.currentRowIndex = this.rows.length - 1;
        this.selectRow(this.currentRowIndex);
        this.resetRowValues();
        this.missingRowKeyError = false;
        this.existingRowKeyError = false;
        this.rowFormula = undefined;
        this.showFormulaSection = false;
        this.printOutput();
        this.selectBlock(blockIndex, false);
      } else {
        this.existingRowKeyError = true;
      }
    }
  }

  updateRow2(blockIndex, row, rowIndex) {
    this.selectBlock(blockIndex, false);
    if (
      this.rows.find((elem) => elem.key === row.key) === undefined ||
      this.rows.findIndex((elem) => elem.key === row.key) === rowIndex
    ) {

      this.blocks[blockIndex].rows[rowIndex] = row;
      this.printOutput();
      this.resetRowValues();
    } else {
      this.existingRowKeyError = true;
      this.sharedService.existingRowKeyError({ blockIndex, row, rowIndex });
    }
  }

  deleteRow2(blockIndex, row) {
    if (this.blocks.length > 0) {
      var index = this.blocks[blockIndex].rows.findIndex(
        (elem) => elem.key === row
      );
      this.blocks[blockIndex].rows.splice(index, 1);
      this.printOutput();
      this.resetRowValues();
    }
  }

  updateColumn2(blockIndex, column, colIndex) {
    this.selectBlock(blockIndex, false);

    if (column.key !== undefined && column.key !== "") {
      if (
        this.columns.find((elem) => elem.key === column.key) === undefined ||
        this.columns.findIndex((elem) => elem.key === column.key) === colIndex
      ) {
        var x = this.columns[colIndex].key;
        this.columns[colIndex].name = column.name;
        this.columns[colIndex].key = column.key;
        this.columns[colIndex].mapping = column.mapping;
        this.columns[colIndex].type =
          column.type === "static text" ? "static_text" : column.type;
        this.columns[colIndex].bold = column.bold;
        for (var i = 0; i < this.rows.length; i++) {
          this.rows[i]["values"].find((elem) => elem.column === x).column =
            column.key;
        }
        this.printOutput();
        this.existingColunmKeyError = false;
      } else {
        this.existingColunmKeyError = true;
        this.sharedService.existingColunmKeyError({
          blockIndex,
          column,
          colIndex,
        });
      }
    } else {
      console.log("something wicked");
    }
  }

  moveRow(blockIndex, row, direction) {
    var index = this.blocks[blockIndex].rows.findIndex(
      (elem) => elem.key === row
    );
    if (direction === "up" && index > 0) {
      var prevRow = this.blocks[blockIndex].rows[index - 1];
      this.blocks[blockIndex].rows[index - 1] =
        this.blocks[blockIndex].rows[index];
      this.blocks[blockIndex].rows[index] = prevRow;
    }
    if (direction === "down" && index < this.blocks[blockIndex].rows.length) {
      var nextRow = this.blocks[blockIndex].rows[index + 1];
      this.blocks[blockIndex].rows[index + 1] =
        this.blocks[blockIndex].rows[index];
      this.blocks[blockIndex].rows[index] = nextRow;
    }

    this.printOutput();
    this.resetRowValues();
  }

  updateRowXX(blockIndex, row) {
    this.rowKey = row.row.key;
    this.rowName = row.row.name;
    this.rowType = row.row.type;
    this.rowStyle = row.row.style;
    this.rowDefault = row.row.default;
    this.rowRequired = row.row.required;
    this.rowGlobal = row.row.global;
    this.rowBold = row.row.bold;
    this.rows = this.blocks[blockIndex].rows;

    if (this.rowKey !== undefined && this.rowKey !== "") {
      if (
        this.rows.find((elem) => elem.key === this.rowKey) === undefined ||
        this.rows.findIndex((elem) => elem.key === this.rowKey) ===
          this.currentRowIndex
      ) {
        this.rows[this.currentRowIndex].name = this.rowName;
        this.rows[this.currentRowIndex].key = this.rowKey;
        this.rows[this.currentRowIndex].type = this.rowType;
        this.rows[this.currentRowIndex].style = this.rowStyle;
        this.rows[this.currentRowIndex].default = this.rowDefault;
        this.rows[this.currentRowIndex].required = this.rowRequired;
        this.rows[this.currentRowIndex].global = this.rowGlobal;
        this.rows[this.currentRowIndex].bold = this.rowBold;

        if (this.rows[this.currentRowIndex]["values"] !== undefined) {
          for (
            var i = 0;
            i < this.rows[this.currentRowIndex]["values"].length;
            i++
          ) {
            if (
              this.rowType !== undefined &&
              this.rowType !== "formula" &&
              this.rowType !== ""
            ) {
              this.rows[this.currentRowIndex]["values"][i]["type"] =
                this.rowType;
            }
            if (
              this.staticTexts[
                this.rows[this.currentRowIndex]["values"][i].column
              ] !== undefined
            ) {
              this.rows[this.currentRowIndex]["values"][i]["value"] =
                this.staticTexts[
                  this.rows[this.currentRowIndex]["values"][i].column
                ];
            }
            if (
              this.formulas[
                this.rows[this.currentRowIndex]["values"][i].column
              ] !== undefined
            ) {
              if (
                this.rows[this.currentRowIndex]["values"][i]["type"] !==
                undefined
              )
                delete this.rows[this.currentRowIndex]["values"][i]["type"];
              this.rows[this.currentRowIndex]["values"][i]["formula"] =
                this.formulas[
                  this.rows[this.currentRowIndex]["values"][i].column
                ];
            }
            if (
              this.checkRequired[
                this.rows[this.currentRowIndex]["values"][i].column
              ] !== undefined
            ) {
              this.rows[this.currentRowIndex]["values"][i]["required"] =
                this.checkRequired[
                  this.rows[this.currentRowIndex]["values"][i].column
                ];
            }
            if (
              this.boldStaticText[
                this.rows[this.currentRowIndex]["values"][i].column
              ] !== undefined
            ) {
              this.rows[this.currentRowIndex]["values"][i]["bold"] =
                this.boldStaticText[
                  this.rows[this.currentRowIndex]["values"][i].column
                ];
            }
          }
        }

        this.printOutput();
        this.resetRowValues();
        this.existingRowKeyError = false;
      } else {
        this.existingRowKeyError = true;
      }
    } else {
    }
  }

  addColumn2(blockIndex, column) {
    this.selectBlock(blockIndex, false);
    this.columns = this.blocks[blockIndex]["columns"];
    if (this.columns === undefined) {
      this.columns = [];
    }
    if (this.columns.find((elem) => elem.key === column.key) === undefined) {
      var c = new Column();
      c.key =
        column.key === undefined || column.key === ""
          ? this.generateColumnKey()
          : column.key;
      c.name =
        column.name === undefined || column.name === "" ? " " : column.name;
      c.type = column.type === "static text" ? "static_text" : column.type;
      c.bold = column.bold;
      this.columns.push(c);
      this.blocks[blockIndex]["columns"] = this.columns;
      for (var i = 0; i < this.columnStates.length; i++) {
        this.columnStates[i] = false;
      }
      this.columnStates.push(true);
      this.columnsInRows.push(true);
      this.currentColumnIndex = this.columns.length - 1;
      this.selectColumn(this.currentColumnIndex);
      this.resetColumnValues();
      this.resetRowValues();
      this.printOutput();
      this.existingColunmKeyError = false;
    } else {
      this.existingColunmKeyError = true;
    }
  }

  showCleanForm() {
    this.formCreation = !this.formCreation;
  }
}
