import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { CompanyRepresentativesStatusName, UserStatusName } from 'src/app/entities/util';
import { TasDataSource } from '../../services/tasGovTable.datasource';
import { UserService } from 'src/app/services/user.service';
import { UserDialogComponent } from '../dialogs/user-dialog/user-dialog.component';
import { SharedService } from 'src/app/services/sharedService';
import { CompanyRepresentativeStatus } from '../../entities/states/companyRepresentativeStatus'
import { CompanyRepresentativeService } from 'src/app/services/companyRepresentative.service';
import { CompanyRepresentativeDialogComponent } from '../dialogs/company-representative-dialog/company-representative-dialog.component';

@Component({
  selector: 'tas-gov-company-representative',
  templateUrl: './company-representative.component.html',
  styleUrls: ['./company-representative.component.css']
})
export class CompanyRepresentativeComponent implements OnInit, AfterViewInit, OnDestroy {


  subscriptionUpdateCompanyRepresentative: any;
  filterObject: {};
  displayedColumns: string[] = ['username', 'name', 'surname', 'company', 'email', 'status'];
  dataSource: TasDataSource;
  statusNames = CompanyRepresentativesStatusName
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private companyRepresentativeService: CompanyRepresentativeService, public dialog: MatDialog, public sharedService: SharedService) {
  }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.companyRepresentativeService)
    this.subscriptionUpdateCompanyRepresentative = this.sharedService.updateCompanyRepresentativeEmmitter.subscribe(val => {
      if (val) {
        this.companyRepresentativeService.updateResource('company-representative', val).subscribe(res => {
          this.loadCompanyRepresentativeTable();
        })
      }
    })
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('company-representative', '', 'asc', 'status', 0, this.paginator.pageSize, this.filterObject);

    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadCompanyRepresentativeTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadCompanyRepresentativeTable())
      )
      .subscribe();

  }

  loadCompanyRepresentativeTable() {
    this.dataSource.loadData('company-representative',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject);
  }

  openCompanyRepresentativeDialog(user): void {
    const dialogRef = this.dialog.open(CompanyRepresentativeDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateCompanyRepresentative) this.subscriptionUpdateCompanyRepresentative.unsubscribe();
  }
getStatusColor(status) {
  switch (status) {
    case CompanyRepresentativeStatus.REQUESTED:
      return '#1DDBE7';
      case CompanyRepresentativeStatus.ACTIVE:
      return '#5EC50D'
      case CompanyRepresentativeStatus.DECLINED:
      return '#E70000'
      case CompanyRepresentativeStatus.SUSPENDED:
      return '#D0ACA9'
  
    default:
      return "#ffffff";
  }
}
}

