import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable, BehaviorSubject, of} from "rxjs";
import {Process} from "../entities/process";
import {ProcessService} from "./process.service";
import {catchError, finalize} from "rxjs/operators";



export class ProcessDataSource implements DataSource<Process> {

    private processSubject = new BehaviorSubject<Process[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public size = 0;

    constructor(private processService: ProcessService) {

    }

    loadProcessess(
                year: string,
                filter:any,
                sortDirection:string,
                sortColumn:string,
                pageIndex:number,
                pageSize:number) {

        this.loadingSubject.next(true);

        this.processService.getProcesses(year, filter, sortDirection, sortColumn,
            pageIndex, pageSize).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(processes => {
                this.processSubject.next(processes['records'])
                this.size = +processes['total'];
            });

    }

    connect(collectionViewer: CollectionViewer): Observable<Process[]> {
        return this.processSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.processSubject.complete();
        this.loadingSubject.complete();
    }

}