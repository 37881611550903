import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Column } from '../form-creator/column';
import { SharedService } from 'src/app/services/sharedService';


@Component({
  selector: 'rdf-formcreation-column-util',
  templateUrl: './column-util.component.html',
  styleUrls: ['./column-util.component.css']
})
export class ColumnUtilComponent implements OnInit {
  @Output() onColumnCreate = new EventEmitter<any>();
  @Output() onColumnCancel = new EventEmitter<any>();
  @Output() onColumnUpdate = new EventEmitter<any>();
  @Output() onColumnDelete = new EventEmitter<any>();
  columnBold = false;
  @Input() column: Column;
  currentColumnKey: any
  @Input() editingState = false;
  prevKey: any
  existingKeyError = false;
  columnTypes = ["string", "number", "date", "static text", "checkbox", "option"]
  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    if (this.column === undefined)
      this.column = new Column();
    this.currentColumnKey = this.column.key
    this.sharedService.existingColunmKeyErrorEmitter.subscribe((obj) => {
      if (obj.column.key === this.column.key){
        this.column.key = this.prevKey
        this.existingKeyError = true
      }
    })
  }


  addColumn() {
    this.column.key = this.currentColumnKey;
    this.onColumnCreate.emit(this.column)
  }

  updateColumn(){
    this.prevKey = this.column.key
    this.column.key = this.currentColumnKey;
    this.onColumnUpdate.emit({ column: this.column })
  }

  deleteColumn(){
    this.onColumnDelete.emit({ column: this.column })
  }

  cancelEditing(){
    this.onColumnCancel.emit()
  }

}
