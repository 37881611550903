import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { UserStatusName, replaceValues, CompanyRepresentativesStatusName } from 'src/app/entities/util';
import { UserService } from 'src/app/services/user.service';
import { UserStatus } from 'src/app/entities/states/userStatus';
import { DialogCommunicator } from '../dialog-communicator';
import { CompanyRepresentative } from 'src/app/entities/companyRepresentative';
import { CompanyRepresentativeStatus } from 'src/app/entities/states/companyRepresentativeStatus';
import { CompanyRepresentativeService } from 'src/app/services/companyRepresentative.service';
import { ProcessService } from 'src/app/services/process.service';
import { ReportService } from 'src/app/services/report.service';
import { ProcessTypeService } from 'src/app/services/process-type.service';

@Component({
  selector: 'gov-company-representative-dialog',
  templateUrl: './company-representative-dialog.component.html',
  styleUrls: ['./company-representative-dialog.component.css']
})
export class CompanyRepresentativeDialogComponent extends DialogCommunicator implements OnInit {
  processes: []
  text: string;
  emitter: EventEmitter;
  companyRepresentative: any;
  statusNames = CompanyRepresentativesStatusName
  companyRepresentativeStatus = CompanyRepresentativeStatus
  branches = [];
  usernames = [];
  repeatPassword = "";
  constructor(
    public dialogRef: MatDialogRef<CompanyRepresentativeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public reportService: ReportService, public processTypeService: ProcessTypeService,
    public companyRepresentativeService: CompanyRepresentativeService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.companyRepresentative = new CompanyRepresentative()
    this.processTypeService.getAllProcessTypes().subscribe(val => {
      this.processes = val
      this.companyRepresentativeService.getResource('company-representative', this.data['_id']).subscribe(rep => {
        this.companyRepresentative = rep
        
        if (this.companyRepresentative['processes'])
        this.companyRepresentative['processes'] = this.processes.filter(elem => {
          return this.companyRepresentative['processes'].find(pr => pr.id === elem['id'])
        });

        this.companyRepresentative['class'] = 'COMPANY_REPRESENTATIVE'
        this.setEntity(this.companyRepresentative);
      }
      )
      this.setConfirmationDialog(this.confirmationDialog)
    })

  }
  ngOnInit() {

  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option === value;
    return false;
  }

  updateStatus(status, text) {
    // this.companyRepresentative.status = status;
    this.onUpdateClick(status, text);
  }

  assignForms() {
    this.onUpdateClick('ASSIGN_FORMS', "ASSIGN_CONFIRM")
  }

}
