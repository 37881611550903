import { Component, OnDestroy,EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { switchMap } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';

import { ProcessType } from 'src/app/entities/processType';
import { ProcessTypeService } from 'src/app/services/process-type.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SharedService } from 'src/app/services/sharedService';
import { FileUploader } from 'ng2-file-upload';
import { ProcessService } from 'src/app/services/process.service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
export interface DialogData {
  name: string;
}
@Component({
  selector: 'app-process-add-edit',
  templateUrl: './process-add-edit.component.html',
  styleUrls: ['./process-add-edit.component.css']
})
export class ProcessAddEditComponent implements OnInit, OnDestroy {
  public uploaderOne: FileUploader;
  public uploaderTwo: FileUploader;
  public uploaderMapping: FileUploader;
  fileNameOne: string;
  fileNameTwo: string;
  fileNameMapping: string;
  allowedMimeType = ['.xls', '.xlsx'];
  currentProcessId: number;
  // stages coming from the process structure
  stages = [];
  processType: ProcessType;
  missingStages = []
 
 
  fileNamePDF: string;
  excelType: any
  currentYear = new Date().getFullYear().toString()
  subscriptionDeleteForm: any
  processId: any
  constructor(public processTypeService: ProcessTypeService, public reportService: ReportService, private router: Router, private route: ActivatedRoute,
    private spinner: NgxSpinnerService,  private toastr: ToastrService, private sharedService: SharedService, public dialog: MatDialog,
    public processService: ProcessService) {
      
  }

  ngOnDestroy(){
    if (this.subscriptionDeleteForm) this.subscriptionDeleteForm.unsubscribe();
  } 
  ngOnInit() {
    this.processType = new ProcessType();
    this.processType['stages']=[]
    this.route
    .queryParams
    .subscribe(params => {
      if (params['id']){
        this.processId = params['id']
        this.setProcess(this.processId)
      }
    this.uploaderOne = new FileUploader({
      url: this.sharedService.apiUrl('upload'), authToken: "Bearer " + localStorage.getItem('tas_gov_access_token'),
      allowedFileType: ["xls", "xlsx"],
      maxFileSize: 50 * 1024 * 1024,
      itemAlias: 'report'
    });

    this.uploaderTwo = new FileUploader({
      url: this.sharedService.apiUrl('upload'), authToken: "Bearer " + localStorage.getItem('tas_gov_access_token'),
      allowedFileType: ["xls", "xlsx"],
      maxFileSize: 50 * 1024 * 1024,
      itemAlias: 'report' 
    });

   

    this.uploaderOne.onAfterAddingFile = (fileItem) => {
      this.fileNameOne = (fileItem.file.name);
    }

 
    this.uploaderTwo.onAfterAddingFile = (fileItem) => {
      this.fileNameTwo = (fileItem.file.name);
    }
 
    this.uploaderOne.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.uploaderOne.clearQueue();
      this.setProcess(this.processId)
      this.fileNameOne = null
    };
    this.uploaderTwo.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.uploaderTwo.clearQueue();
      this.fileNameTwo = null
      this.setProcess(this.processId)
    };
 
   
      
    });
   this.subscriptionDeleteForm = this.sharedService.deleteFormEmmiter.subscribe(result=> {
      this.spinner.show()
      this.processTypeService.deleteResource('process-types',this.processType).subscribe(result => {
        this.spinner.hide()
        this.toastr.success('Success!', `Process ${this.processType.name} successfully deleted`, {
          toastClass: "ngx-toastr border-red",
          timeOut: 3000
        });
        this.router.navigate(['/formcreation_foradmin525'])
      })
    })
  
  }
 
  setProcess(processId){
    
    this.processTypeService.getProcessType(processId).subscribe(prType => {
      this.processType = prType
      this.uploaderOne.setOptions({
        additionalParameter: { 'processId': processId,'num': 1 }
    })
    this.uploaderTwo.setOptions({
      additionalParameter: { 'processId': processId,'num': 2 }
  })
  this.uploaderMapping = new FileUploader({
    url: this.sharedService.apiUrl('upload/excel',this.processType.key), authToken: "Bearer " + localStorage.getItem('tas_gov_access_token'),
    allowedFileType: ["xls", "xlsx"],
    maxFileSize: 50 * 1024 * 1024,
    itemAlias: this.processType.key 
  });

  this.uploaderMapping.onAfterAddingFile = (fileItem) => {
    this.fileNameMapping = (fileItem.file.name);
  } 
  this.uploaderMapping.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    this.uploaderMapping.clearQueue();
    this.setProcess(this.processId)
    this.fileNameMapping = null
  };
  this.uploaderMapping.setOptions({
    additionalParameter: { 'processId': processId,  'processKey':this.processType.key}
})
    })
  }

  deleteFileEmmitter: EventEmitter<boolean> = new EventEmitter(); 
  subscriptionConfirm: any 
  openDeleteFileDialog(file) { 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
      width: '500px', 
      data: { text: "DIALOG.PROCESS_DELETE_FILE", emitter: this.deleteFileEmmitter, obj: file, action:"CONFIRM" } 
    }); 
    this.subscriptionConfirm = this.sharedService.confirmDialogEmitter.subscribe(val => { 
      if (val) { 
        this.spinner.show() 
        this.processTypeService.deleteFile(this.processType.id,val).subscribe(res=>{
          this.setProcess(this.processId)
          this.spinner.hide() 
        })
      } 
    }) 
 
    dialogRef.afterClosed().subscribe(result => { 
    }); 
  } 

  populatePages(processType) {
    this.processType = processType;
    this.currentProcessId = this.processType.id;
    this.reportService.getPages(processType['key'])
      .subscribe(
        data => {
          this.stages = data.stages;
          var stgs = this.stages.filter(elem => elem.required === true).map(a => a.key);
          var x = this.processType['stages'].map(a => a.key);

        },
        err => {
          console.log(err);
        }
      );
  }

  downloadUploadFile(name) {
    this.spinner.show();
    this.processService.downloadUploadFile(name).subscribe
      (data => {
        this.downloadPrc(data,name)
      });
  }

  downloadMappingFile(name) {
    this.spinner.show();
    this.processService.downloadMappingFile(name).subscribe
      (data => {

        this.downloadPrc(data,name)
      });
  }
 
  downloadPrc(data,name){
    this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = name;
        saveAs(file, filename);
  }

  list() {
    this.router.navigate(['formcreation_foradmin525']);
  }

  openInEditor(stage) {
    this.router.navigate(['/edit_form/'+this.processType.year+"/"+this.processType.key+"/"+ stage.key], {queryParams: {id:this.processType.id, name:stage.name, type:stage.type}});
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.processType['stages'], event.previousIndex, event.currentIndex);
  }

  addNewStage(){
    var counter=1
  
    while (this.processType['stages'].find(elem=>elem.key==="stage_"+counter)){
      counter++;
    }
    var key = "stage_"+counter
 
   var x = {key:key, name: "new stage "+counter, type:"page", blocks:[]}
   this.processType['stages'].push(x);
   this.saveProcess(false);
  }

  saveProcess(showMessage){
    this.spinner.show()
    this.processTypeService.updateResource('process-types',this.processType).subscribe(result => {
 
      this.processType = result
      this.spinner.hide()
      if (showMessage){
        this.router.navigate(['process_type_add_edit'], { queryParams: { id: result.id }});
        this.toastr.success('Success!', `Process ${this.processType.name} successfully saved`, {
          toastClass: "ngx-toastr border-red",
          timeOut: 3000
        });
      }
     
    })
    }

    deleteProcess(){

      const dialogRefConf = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: { text: "DIALOG.DELETE_FORM_CONFIRM", emitter: this.sharedService.deleteForm, obj: null, action:"DELETE_PROCESS" }
      });
     

      }

  removeStage(key){
    this.processType['stages'].splice(key, 1);
  }
  

}

 