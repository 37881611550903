export class Row {

    name = "";
    key = "";
    type = "";
    style = "";
    default = true;
    required = false;
    hasAttachment = false;
    fusionTransfer = false;
    global = false;
    bold = false;
    mapping = "";
}
